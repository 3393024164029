<template>
    <v-dialog
        v-model="show"
        width="600"
    >
        <v-card>
            <v-card-title class="headline primary secondary--text">
                NEWS!
            </v-card-title>

            <v-card-subtitle class="pt-10 text-h6" v-html="msg">
            </v-card-subtitle>

            <v-card-text v-if="image">
                    <v-img
                        width="100%"
                        :src="image"
                    ></v-img>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    elevation="0"
                    @click="closed = true"
                >
                Na endlich!
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data () {
        return {
            closed: false,
        }
    },
    props: {
        msg: {
            type: String,
            required: false,
            default: '',
        },
        image: {
            type: String,
            required: false,
            default: '',
        }
    },
    computed: {
        ...mapGetters(['getName']),
        hasName() {
            return (this.getName !== '' && this.getName !== undefined && this.getName !== null)
        },
        hasMessage() {
            return (this.msg !== '' && this.msg !== undefined && this.msg !== null)
        },
        show() {
            return (
                this.hasName
                && this.closed === false
                && this.hasMessage
            )
        }
    }
}
</script>