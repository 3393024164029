<template>
        <v-list-item>
                <v-list-item-icon>
                    <v-icon large color="blue darken-4">fa-poo</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title style="font-size:140%;">JOKER!</v-list-item-title>
                    <v-list-item-title>{{name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.ts}}</v-list-item-subtitle>
                </v-list-item-content>
        </v-list-item>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'joker-display',
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters(['getPlayers']),
        name() {
            const player = this.getPlayers.filter((player) => player.id === this.item.c)
            return (player.length) ? player[0].name : this.item.c
        }
    },
}
</script>