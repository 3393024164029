<template>
        <v-list v-if="items">
            <v-list-item
                v-for="(player, i) in items"
                :key="player.id"
            >
                <v-list-item-content>
                    <v-list-item-title v-text="(i+1) + '. ' + player.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                <v-icon
                    v-if="player.master === 1"
                    color="yellow"
                >
                    mdi-star
                </v-icon>
                </v-list-item-icon>

                <v-list-item-avatar v-if="player.avatar">
                        <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>
            </v-list-item>
        </v-list>
</template>
<script>
export default {
    name: 'player-list-display',
    props: {
        items: {
            type: Array,
            required: true,
        }
    }
}
</script>