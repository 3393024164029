<template>
  <div v-if="getPlayers.length || getAnswers.length">
    <vuetify-audio
        :file="buzzerSounds[0]"
        color="success"
        :ended="audioFinish"
        autoPlay
        flat
        style="display: none; visibility: hidden;"
        v-if="playSound"
    ></vuetify-audio>
    <v-card v-if="getAnswers.length" class="mb-10" elevation="10">
      <v-card-title class="headline">
        Participations {{ getAnswers.length }} / {{ numberOfPlayers }}
      </v-card-title>

      <v-alert
          dense
          type="info"
          v-if="getRevealed"
      >
        Answer: {{ this.getFormattedAnswer }}
      </v-alert>

      <joker-entry
          v-for="(joker, i) in questionJokers"
          :key="i"
          :item="joker"
      ></joker-entry>

      <answer-entry
          v-for="(answer, i) in getAnswers"
          :key="i"
          :item="answer"
      ></answer-entry>
    </v-card>


    <v-card v-if="getPlayers.length" elevation="10">
      <v-card-title class="subline">
        connected users
      </v-card-title>
      <player-list-display :items="getOrderedOnlinePlayers"></player-list-display>
      <v-divider></v-divider>
      <v-card-title class="subline">
        disconnected users
      </v-card-title>
      <player-list-display :items="getOrderedOfflinePlayers"></player-list-display>
    </v-card>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import VuetifyAudio from 'vuetify-audio'

import PlayerListDisplay from './player-list/list'
import AnswerEntry from './player-list/answer-entry'
import JokerEntry from './player-list/joker-entry'
import numbro from "numbro";

export default {
  name: 'player-list',
  data() {
    return {
      buzzerSounds: [
        '/assets/sound/buzzer-1-freesoundlibrary.com-vol-down.mp3'
      ],
      playingSound: false,
    }
  },
  components: {
    PlayerListDisplay,
    AnswerEntry,
    JokerEntry,
    VuetifyAudio,
  },
  computed: {
    ...mapGetters([
      'getPlayers',
      'getAnswers',
      'getJokers',
      'getQuestionType',
      'getQuestionId',
      'getRevealed',
      'getQuestionA',
      'getMasterView',
      'getName',
      'getOptionSound',
    ]),
    playSound() {
      return (this.getName !== '' && this.playingSound && this.getOptionSound === true)
    },
    numberOfPlayers() {
      const players = this.getPlayers.filter((item) => {
        return item.active && item.master !== 1
      })
      if (!players.length) {
        return []
      }
      return (players.length) ? players.length : 0
    },
    getFormattedAnswer() {
      if (this.getQuestionType === "guess") {
        return numbro(this.getQuestionA).format({thousandSeparated: true})
      } else {
        return this.getQuestionA
      }
    },
    getOrderedOnlinePlayers() {
      const players = this.getPlayers
      if (!players.length) {
        return []
      }
      return players.filter((item) => {
        return item.active
      }).sort((a, b) => {
        if (a.active > b.active) {
          return -1
        } else if (a.active < b.active) {
          return 1
        } else {
          return 0
        }
      })
    },
    getOrderedOfflinePlayers() {
      const players = this.getPlayers
      if (!players.length) {
        return []
      }
      return players.filter((item) => {
        return !item.active
      }).sort((a, b) => {
        if (a.name > b.name) {
          return -1
        } else if (a.name < b.name) {
          return 1
        } else {
          return 0
        }
      })
    },
    questionJokers() {
      return this.getJokers.filter((joker) => joker.q === this.getQuestionId)
    }
  },
  watch: {
    getAnswers(nv, ov) {
      if (nv && ov && nv.length > ov.length && this.playingSound === false) {
        this.playingSound = true
      }
    }
  },
  methods: {
    audioFinish() {
      this.playingSound = false
    }
  }
}
</script>