import Vue from 'vue'
import VueRouter from 'vue-router'

import GameView from '../components/game-view.vue'
import MasterView from '../components/master-view.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', component: GameView },
    { path: '/masterview', component: MasterView }
]

const router = new VueRouter({
    routes // short for `routes: routes`
})

export default router