const transformedQuestion = (question) => {
    return {
        type: question.type,
        id: question.id,
        played: [],
        revealed: (question.r === 1),
        a: question.a
    }
}

function toggleNameModel({ state, commit }) {
    commit('setOpenNameModal', !state.openNameModal)
}
function isMasterView({ commit }) {
    commit('setMasterView', true)
}

function resetQuestionState({ commit }, includeEmptyQuestion) {
    commit('setBuzzed', false)
    commit('setRevealed', false)
    commit('setAnswers', [])
    if (includeEmptyQuestion) {
        commit('setQuestionDetails', transformedQuestion({ type: null, id: null }))
    }
}

function socket_newQuestion({ commit, dispatch }, payload) {
    if (payload.active) {
        const nq = transformedQuestion(payload)
        commit('setQuestionDetails', nq)
        commit('setRevealed', (nq.revealed === true))
        dispatch('resetQuestionState')
    }
}

function socket_infoCurrentQuestion({ commit }, payload) {
    if (payload.active) {
        const nq = transformedQuestion(payload)
        commit('setRevealed', (nq.revealed === true))
        commit('setQuestionDetails', nq)
    }
}

function socket_deleteQuestion({ dispatch }) {
    dispatch('resetQuestionState', true)
}

function socket_savedAnswer({ commit }) {
    commit('setBuzzed', true)
}

function socket_resetQuestion({ dispatch }) {
    dispatch('resetQuestionState')
}

function socket_endQuestion({ dispatch }) {
    dispatch('resetQuestionState', true)
}

function socket_revealAnswers({ commit }) {
    commit('setRevealed', true)
}

function socket_joker({ commit, state }, joker) {
    const q = state.question
    q.joker = joker
    commit('setQuestionDetails', q)
}

export default {
    toggleNameModel,
    isMasterView,
    resetQuestionState,
    socket_newQuestion,
    socket_infoCurrentQuestion,
    socket_deleteQuestion,
    socket_savedAnswer,
    socket_resetQuestion,
    socket_endQuestion,
    socket_revealAnswers,
    socket_joker,
}