<template>
<v-dialog
    v-model="show"
    persistent
    width="300"
>
    <v-card
        color="secondary"
        dark
    >
        <v-card-text class="pa-3">
            Please stand by ... Game Master must join
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-5 mt-5"
            ></v-progress-linear>
        </v-card-text>
    </v-card>
</v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'please-stand-by-master-missing',
    computed: {
        ...mapGetters(['getMasterView', 'getPlayers']),
        show() {
            return (this.$socket
                && this.$socket.connected === true
                && this.getMasterView === false
                && this.hasMaster === false
            )
        },
        hasMaster() {
            const master = this.getPlayers.filter((item) => { return (item.master == 1 && item.active == 1) })
            return (master.length === 1)
        }
    },
}
</script>