import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        name: null,
        masterView: false,
        openNameModal: false,
        ranking: {},
        connected: false,
        errors: {
            name: false,
            answer: false,
        },
        players: [],
        answers: [],
        jokers: [],
        buzzed: false,
        revealed: false,
        question: {
            id: null,
            type: null,
            played: [],
            revealed: false,
            joker: null,
        },
        options: {
            sound: false,
        }
    },
    getters,
    mutations,
    actions,
})

export default store