function getPlayers(state) {
  return state.players
}

function getRanking(state) {
  return state.ranking
}

function getMasterView(state) {
  return state.masterView
}

function getName(state) {
  return state.name
}

function getBuzzed(state) {
  return state.buzzed
}

function getRevealed(state) {
  return state.revealed
}

function getOpenNameModal(state) {
  return state.openNameModal
}

function getConnected(state) {
  return state.connected
}

function getAnswers(state) {
  return state.answers
}

function getErrors(state) {
  return state.errors
}

function getQuestionDetails(state) {
  return state.question
}

function getQuestionType(state) {
  return (state.question) ? state.question.type : null
}

function getQuestionId(state) {
  return (state.question) ? state.question.id : null
}

function getQuestionPlayed(state) {
  return (state.question) ? state.question.played : null
}

function getQuestionRevealed(state) {
  return (state.question) ? state.question.revealed : null
}

function getQuestionA(state) {
  return (state.question) ? state.question.a : null
}

function getOptions(state) {
  return state.options
}

function getOptionSound(state) {
  return (state.options) ? state.options.sound : false
}

function getJokers(state) {
  return state.jokers
}

export default {
  getName,
  getBuzzed,
  getRevealed,
  getMasterView,
  getPlayers,
  getAnswers,
  getRanking,
  getOpenNameModal,
  getConnected,
  getErrors,
  getQuestionDetails,
  getQuestionId,
  getQuestionType,
  getQuestionPlayed,
  getQuestionRevealed,
  getQuestionA,
  getOptions,
  getOptionSound,
  getJokers,
}