function setPlayers(state, players) {
  state.players = players
}
function setRanking(state, ranking) {
  state.ranking = ranking
}
function setMasterView(state, masterView) {
  state.masterView = masterView
}
function setName(state, name) {
  state.name = name
}
function setOpenNameModal(state, newState) {
  state.openNameModal = newState
}
function setConnected(state, connected) {
  state.connected = connected
}
function setBuzzed(state, buzzed) {
  state.buzzed = buzzed
}
function setRevealed(state, revealed) {
  state.revealed = revealed
}
function setAnswers(state, answers) {
  state.answers = answers
}
function setOptionSound(state, sound) {
  state.options.sound = sound
}
function setQuestionDetails(state, questionDetails) {
  state.question = questionDetails
}

function SOCKET_FAILED_NAME(state) {
  state.errors.name = true
  state.name = null
}

function SOCKET_SAVED_NAME(state) {
  state.errors.name = false
}

function SOCKET_FAILED_ANSWER(state) {
  state.errors.answer = true
  state.buzzed = false
}

function SOCKET_SAVED_ANSWER(state) {
  state.errors.name = false
  state.buzzed = true
}

function SOCKET_PLAYER_LIST(state, payload) {
  state.players = payload.players
}

function SOCKET_JOKER(state, payload) {
  state.jokers = payload.jokers
}

function SOCKET_ANSWERS(state, answers) {
  state.answers = answers
}

export default {
  setPlayers,
  setRanking,
  setMasterView,
  setName,
  setOpenNameModal,
  setConnected,
  setBuzzed,
  setRevealed,
  setAnswers,
  setOptionSound,
  setQuestionDetails,
  SOCKET_FAILED_NAME,
  SOCKET_SAVED_NAME,
  SOCKET_FAILED_ANSWER,
  SOCKET_SAVED_ANSWER,
  SOCKET_PLAYER_LIST,
  SOCKET_JOKER,
  SOCKET_ANSWERS,
}