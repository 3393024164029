<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="basilicom interes quiz"
          class=""
          width="240"
          src="./assets/logo-bas-edited.png"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>
        <span style="text-align:center;">
          {{ $socket.connected ? 'Connected' : 'Disconnected' }}
          <span v-if="$socket.connected"><br><small>ID: {{ $socket.client.id }}</small></span>
        </span>
      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="getName">
        <template
          class="Xname"
          v-slot:activator="{ on, attrs }"
        >
          <span 
            v-bind="attrs"
            v-on="on"
            @click="openNameModal"
          >
            <v-chip color="secondary" >
              <span style="color: #373737" v-html="nameDisplay">
              </span>
            </v-chip>
          </span>
        </template>
        <span>Wanna change? Click to edit, you shape-shifter you!</span>
      </v-tooltip>

      
    </v-app-bar>

    <v-main>
        <router-view></router-view>
    </v-main>
    <name-modal></name-modal>
    <please-stand-by-disconnected-screen></please-stand-by-disconnected-screen>
    <please-stand-by-master-missing-screen></please-stand-by-master-missing-screen>
    <news-dialog :msg="newsMessage" :image="newsImage"></news-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import nameModal from './components/name-modal'
import pleaseStandByDisconnectedScreen from './components/please-stand-by-connecting-to-server'
import pleaseStandByMasterMissingScreen from './components/please-stand-by-missing-master'
import newsDialog from './components/news-dialog'

export default {
  name: 'App',
  title: 'Buzz!',
  favicon: '/assets/qu.ico',
  components: {
    nameModal,
    pleaseStandByDisconnectedScreen,
    pleaseStandByMasterMissingScreen,
    newsDialog,
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$socket.$subscribe('event_name', payload => {
      console.log(payload)
    });
  },
  computed: {
    ...mapGetters(['getName', 'getConnected']),
    nameDisplay() {
      return `Hi ${this.getName}! 👋 &nbsp;<small>[ ？]</small>`
    },
    newsMessage() {
      return (window.newsMsg) ?? null
    },
    newsImage() {
      return (window.newsImg) ?? null
    }
  },
  methods: {
    ...mapActions(['toggleNameModel']),
    openNameModal() {
      this.toggleNameModel()
    }
  },
  watch: {
    getConnected(nv) {
      if (nv === true) {
        console.log(123)
      }
    }
  }
};
</script>
