function getIcon(vm) {
    const { favicon } = vm.$options
    if (favicon) {
        return typeof favicon === 'function'
            ? favicon.call(vm)
            : favicon
    }
}
export default {
    created() {
        const favicon = getIcon(this)
        if (favicon) {
            console.log(favicon)
            document.querySelector('[rel=icon]').href = favicon
        }
    }
}