<template>
<v-card>
    <v-card-title class="headline">
        Options
    </v-card-title>
    <v-card-text>
        <v-switch
            v-model="sound"
            inset
            large
            color="secondary"
            :label="`(Buzz) Sound: ${sound.toString()}`"
        ></v-switch>
    </v-card-text>
</v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'options-view',
    data() {
        return {
            sound: false,
        }
    },
    computed: {
        ...mapGetters(['getOptions', 'getOptionSound'])
    },
    mounted() {
        this.sound = this.getOptionSound
    },
    methods: {
        ...mapMutations(['setOptionSound']),
    },
    watch: {
        sound(nv, ov) {
            if (nv !== ov) {
                this.setOptionSound(nv)
            }
        }
    }
}
</script>