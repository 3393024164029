<template>
<v-dialog
    persistent
    max-width="600px"
    v-model="show"
>
    <v-card>
        <v-form @submit.prevent="saveName">
            <v-card-title>
                <span class="headline">Name</span>
            </v-card-title>
            <v-card-text>
                <v-alert
                    v-if="getErrors.name"
                    type="error"
                >
                    Could not save name. Please try again
                </v-alert>
                <v-text-field
                    label="Name"
                    hint="name is used in live app, but not in ranking"
                    v-model="name"
                    autocomplete="off"
                    persistent-hint
                    required
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary darken-1"
                    @click="close"
                    v-if="getName"
                >
                    Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary darken-1"
                    @click="saveName"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'name-modal',
    data() {
        return {
            name: null
        }
    },
    computed: {
        ...mapGetters(['getName', 'getOpenNameModal', 'getErrors', 'getMasterView']),
        show() {
            return (
                    (this.getName === null || this.getName === '' || this.getOpenNameModal === true)
                    &&
                    (this.$socket && this.$socket.connected === true)
                )
        }
    },
    mounted() {
        this.name = this.getName
    },
    methods: {
        ...mapMutations(['setName', 'setOpenNameModal']),
        close() {
            this.setOpenNameModal(false)
        },
        saveName() {
            if (this.$socket && this.$socket.connected) {
                this.$socket.client.emit('register_name', this.name, this.getMasterView);
            }
            this.setOpenNameModal(false)
            this.setName(this.name)
        }
    },
}
</script>