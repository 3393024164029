import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

import store from '../store/store'

const socketUrl = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:3000' : 'https://socket.basilicom.live:4433'

const socket = io(socketUrl);
Vue.use(VueSocketIOExt, socket, { store });

export default socket