<template>
  <v-data-table
      :headers="headers"
      :items="players"
      :options="options"
      item-key="name"
      class="elevation-1"
      :search="search"
      hide-default-footer
      :hide-default-header="loading"
      :loading="loading"
      loading-text="Loading... Please wait"
  >
    <template v-slot:top v-if="!loading">
      <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"></v-text-field>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'current-ranking',
  data: () => ({
    loading: true,
    interval: null,
    headers: [
      {
        text: 'Player',
        align: 'start',
        value: 'name',
      },
      {text: 'Points', value: 'correct'},
    ],
    options: {
      itemsPerPage: 1000
    },
    search: null,
  }),
  computed: {
    ...mapGetters(['getRanking']),
    players() {
      if (this.getRanking.length) {
        return this.getRanking
      }
      return []
    }
  },
  watch: {
    getPlayers(nv) {
      if (nv.length) {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapMutations(['setRanking']),
    load() {
      this.loading = true
      fetch('https://scores.basilicom.live/api/quizzes/current/team/b72a4914-3f6f-455d-84fb-b38f15443ea0')
          .then(response => response.json())
          .then(data => {
            if (data.participants) {
              const rData = [];
              data.participants.forEach((person) => {
                rData.push({name: person.name, correct: person.totalScore})
              })
              this.setRanking(rData)
              this.loading = false
            }
          });

    }
  },
  created() {
    if (this.interval) {
      window.clearInterval(this.interval)
    }
  },
  mounted() {
    window.setTimeout(this.load, 2000)
    if (this.interval) {
      window.clearInterval(this.interval)
    }
    this.interval = setInterval(() => {
      this.load()
    }, 90000)
  }
}
</script>
