import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import socket from './socket/socket'
import store from './store/store'
import router from './router/router'

import titleMixin from './mixins/titleMixin'
import faviconMixin from './mixins/faviconMixin'

Vue.mixin(titleMixin)
Vue.mixin(faviconMixin)

Vue.config.productionTip = false

require('./scss/styles.scss')

new Vue({
  store,
  socket,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
