<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon
          large
          color="yellow darken-2"
      >fa-lightbulb
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ item.user }}</v-list-item-title>
      <v-list-item-subtitle>{{ item.ts }}</v-list-item-subtitle>
      <div v-if="getMasterView===true && !showJeopardyAnswer && !getRevealed">
        A: {{ getItemsAnswer }}
      </div>
      <div v-if="showJeopardyAnswer">
        <v-alert
            border="top"
            color="info darken-2"
            dark
        >
          {{ getItemsAnswer }}
        </v-alert>
      </div>
    </v-list-item-content>
    <v-list-item-icon v-if="showIcons">
      <v-chip
          class="mr-7"
          v-if="getRevealed"
      >Answered: {{ getItemsAnswer }}
      </v-chip>

      <v-icon
          large
          v-if="showUnclearIcon"
          color="info"
      >fa-question-circle
      </v-icon>
      <v-icon
          large
          v-else-if="showCorrectIcon"
          color="success"
      >fa-check-circle
      </v-icon>
      <v-icon
          large
          v-else-if="showGuessInfoIcon"
          color="info"
      >fa-hand-peace
      </v-icon>
      <v-icon
          large
          v-else-if="showNotCorrectIcon"
          color="red"
      >fa-times-circle
      </v-icon>
    </v-list-item-icon>
    <v-list-item-icon
        v-if="showGuessInfo"
        style="display: flex; align-items: center; "
    >
            <span
                style="text-align:center;"
                class="text-h6"
            >
                {{ guessDiff }}
            </span>
    </v-list-item-icon>
  </v-list-item>
</template>
<script>
import {mapGetters} from "vuex"
import numbro from "numbro";

export default {
  name: "player-list-display",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "getQuestionType",
      "getRevealed",
      "getQuestionA",
      "getMasterView",
    ]),
    getItemsAnswer() {
      if (this.item.details && this.item.details.answer) {
        if (this.getQuestionType === "guess") {
          return numbro(this.item.details.answer).format({thousandSeparated: true})
        } else {
          return this.item.details.answer
        }
      }
      return null
    },
    guessDiff() {
      return numbro(this.item.details.answer * 1 - this.getQuestionA * 1).format({thousandSeparated: true})
    },
    isCorrect() {
      if (this.getItemsAnswer === this.getQuestionA) {
        return true
      } else if (
          this.getQuestionType === "guess" &&
          this.getItemsAnswer * 1 === this.getQuestionA * 1
      ) {
        return true
      }
      return false
    },
    showJeopardyAnswer() {
      return (
          this.getQuestionType === "jeopardy" && this.getRevealed === true
      )
    },
    showIcons() {
      return (
          this.getQuestionType === "mchoice" ||
          this.getQuestionType === "guess"
      )
    },
    showGuessInfo() {
      return (
          this.getQuestionType === "guess" &&
          (this.getRevealed === true || this.getMasterView === true)
      )
    },
    showUnclearIcon() {
      return this.getRevealed === false && this.getMasterView === false
    },
    showCorrectIcon() {
      return (
          (this.getRevealed === true || this.getMasterView === true) &&
          this.isCorrect === true
      )
    },
    showNotCorrectIcon() {
      return (
          (this.getRevealed === true || this.getMasterView === true) &&
          this.isCorrect === false
      )
    },
    showGuessInfoIcon() {
      return (
          (this.getRevealed === true || this.getMasterView === true) &&
          this.isCorrect === false &&
          this.getQuestionType === "guess"
      )
    },
  },
}
</script>