<template>
<v-row no-gutters>
    <v-col class="pa-2 col-12 col-md-4">
        <player-list></player-list>
    </v-col>

    <v-col class="pa-2 col-12 col-md-4 order-first order-md-0">
        <inputs></inputs>
        <options></options>
    </v-col>
    
    <v-col class="pa-2 col-12 col-md-4">
        <v-card
            elevation="10"
            outlined
        >
            <v-card-title class="headline">
                Ranking
            </v-card-title>
            <v-card-text>
                <ranking></ranking>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import ranking from './ranking'
import playerList from './player-list'
import inputs from './input-view'
import options from './options-view'

export default {
    name: 'game-view',
    components: {
        ranking,
        playerList,
        inputs,
        options,
    },
    computed: {
    }
}
</script>