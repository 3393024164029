<template>
<v-row no-gutters>
    <v-col class="pa-2">
        <h2>Player</h2>
        <player-list></player-list>
    </v-col>

    <v-col class="pa-2">
        <div v-if="getQuestionDetails && getQuestionDetails.id" class="mb-10">
            <h2>current question</h2>
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <strong>type:</strong> {{ getQuestionDetails.type }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <strong>answer:</strong> {{ getQuestionA }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <div class="pa-2">
                <v-btn
                    color="primary"
                    class="mb-4"
                    large
                    block
                    @click.prevent="resetQuestion"
                    v-if="getQuestionDetails.type === 'buzz'"
                    >
                    RESET BUZZER
                </v-btn>
                <v-divider></v-divider>
                <v-btn
                    color="success"
                    class="mb-4"
                    large
                    block
                    @click.prevent="revealQuestion"
                    v-if="getQuestionDetails.type !== 'buzz'"
                    >
                    REVEAL
                </v-btn>
                <v-btn
                    color="secondary"
                    large
                    block
                    @click.prevent="endQuestion"
                    >
                    END QUESTION
                </v-btn>

                </div>
            </v-card>
        </div>

        <h2>create</h2>
        <v-card class="mb-10">
            <v-card-text>
                <v-select
                    :items="questionTypes"
                    filled
                    label="question type"
                    v-model="quSelect"
                ></v-select>
                <v-select
                    :items="mchoiceTypes"
                    filled
                    label="answer"
                    v-model="mchoiceAnswer"
                    v-if="quSelect === 'mchoice'"
                ></v-select>
                <v-text-field
                    v-if="quSelect === 'guess'"
                    type="number"
                    filled
                    v-model="guessAnswer"
                ></v-text-field>
                <v-textarea
                    v-if="quSelect === 'jeopardy'"
                    filled
                    v-model="jeopardyAnswer"
                ></v-textarea>
                <v-btn elevation="2" block large dark @click="createNewQuestion">
                    create new question
                </v-btn>
            </v-card-text>
        </v-card>

        <options></options>
    </v-col>
    
    <v-col class="pa-2">
        <h2>current ranking</h2>
        <v-card
            elevation="10"
            outlined
        >
            <v-card-text>
                <ranking></ranking>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import ranking from './ranking'
import playerList from './player-list'
import options from './options-view'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'master-view',
    title: 'Buzz! Game Master View!',
    favicon: '/assets/star-favicon.png',
    components: {
        ranking,
        playerList,
        options,
    },
    data() {
        return {
            quSelect: null,
            mchoiceAnswer: null,
            guessAnswer: null,
            jeopardyAnswer: null,
            questionTypes: [
                'buzz',
                'mchoice',
                'guess',
                'jeopardy',
            ],
            mchoiceTypes: [
                'A',
                'B',
                'C',
                'D'
            ],
        }
    },
    computed: {
        ...mapGetters(['getQuestionDetails', 'getQuestionId', 'getQuestionA']),
        hasConnection() {
            return (this.$socket && this.$socket.connected)
        }
    },
    mounted() {
        this.isMasterView()
    },
    watch: {
        quSelect() {
            this.mchoiceAnswer = null
            this.guessAnswer = null
            this.jeopardyAnswer = null
        }
    },
    methods: {
        ...mapActions(['isMasterView']),
        createNewQuestion() {
            if (this.questionTypes.indexOf(this.quSelect) !== -1 && this.hasConnection) {
                
                let correctAnswer = null

                if (this.quSelect === 'mchoice' && this.mchoiceTypes.indexOf(this.mchoiceAnswer) === -1) {
                    return;
                } else if (this.quSelect === 'mchoice') {
                    correctAnswer = this.mchoiceAnswer
                }

                if (this.quSelect === 'guess' && (!this.guessAnswer || this.guessAnswer === '')) {
                    return;
                } else if (this.quSelect === 'guess') {
                    correctAnswer = this.guessAnswer
                }

                if (this.quSelect === 'jeopardy' && (!this.jeopardyAnswer || this.jeopardyAnswer === '')) {
                    return;
                } else if (this.quSelect === 'jeopardy') {
                    correctAnswer = this.jeopardyAnswer
                }

                this.$socket.client.emit('create_question', this.quSelect, correctAnswer);
                this.quSelect = null
            }
        },
        resetQuestion() {
            if (this.getQuestionId && this.hasConnection) {
                this.$socket.client.emit('send_reset_question', this.getQuestionId, null);
            }
        },
        endQuestion() {
            if (window.confirm("Do you really want to end this question?")) { 
                if (this.getQuestionId && this.hasConnection) {
                    this.$socket.client.emit('end_question', this.getQuestionId, null);
                }
            }
        },
        revealQuestion() {
            if (window.confirm("Do you really want to reveal the answers for this question?")) { 
                if (this.getQuestionId && this.hasConnection) {
                    this.$socket.client.emit('reveal_answers_for_question', this.getQuestionId, null);
                }
            }
        }
    }
}
</script>