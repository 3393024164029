<template>
  <div>
    <v-snackbar
        v-model="getErrors.answer"
        top
        color="secondary"
        elevation="24"
    >
      We had trouble to save your answer/buzz. Please try again!
    </v-snackbar>
    <v-card
        elevation="10"
        outlined
        class="mx-auto"
        style="margin-bottom: 20px;"
        v-if="getType === 'buzz'"
    >
      <v-card-title>
      </v-card-title>
      <v-card-text
          :aspect-ratio="1/1"
          style="position: relative;"
      >
        <v-img
            src="assets/1x1.png"
            :aspect-ratio="1/1"
            style="width: 30%"
        ></v-img>
        <a
            href="#"
            class="buzzer"
            @click.prevent="buzz"
            :disabled="getBuzzed"
        ></a>
      </v-card-text>
      <v-btn
          block
          large
          @click.prevent="sendJoker()"
          color="secondary"
          v-if="hasBuzzes && 1 === 2"
      >
        DRAW JOKER
      </v-btn>
    </v-card>

    <v-card
        elevation="10"
        outlined
        v-if="getType === 'mchoice'"
    >
      <v-card-text style="justify-content:center; display: flex;">
        <v-btn-toggle v-model="mchoice">
          <v-btn
              large
              @click.prevent="answerMchoice('A')"
              color="secondary"
              :disabled="getRevealed"
          >
            A
          </v-btn>
          <v-btn
              large
              @click.prevent="answerMchoice('B')"
              color="secondary"
              :disabled="getRevealed"
          >
            B
          </v-btn>
          <v-btn
              large
              @click.prevent="answerMchoice('C')"
              color="secondary"
              :disabled="getRevealed"
          >
            C
          </v-btn>
          <v-btn
              large
              @click.prevent="answerMchoice('D')"
              color="secondary"
              :disabled="getRevealed"
          >
            D
          </v-btn>
        </v-btn-toggle>
      </v-card-text>
    </v-card>

    <v-card
        elevation="10"
        outlined
        v-if="getType === 'guess'"
    >
      <v-card-text>
        <v-chip
            v-if="guess"
            class="mb-2"
        >{{ formattedGuessInput }}
        </v-chip>
        <v-text-field
            type="number"
            background-color="secondary"
            color="primary"
            v-model="guess"
            filled
            label="your guess"
            class="mb-2"
        ></v-text-field>
        <v-btn
            color="primary"
            block
            large
            @click.prevent="answerGuess"
            :disabled="getRevealed"
        >send guess
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card
        elevation="10"
        outlined
        v-if="getType === 'jeopardy'"
    >
      <v-card-text>
        <v-textarea
            v-model="jeopardy"
            background-color="secondary"
            color="primary"
            filled
            label="your question"
        ></v-textarea>
        <v-btn
            color="primary"
            block
            large
            @click.prevent="answerJeopardy"
            :disabled="getRevealed"
        >send it
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"
import numbro from "numbro"
import allLanguages from "numbro/dist/languages.min"

Object.values(allLanguages).forEach(function (data) {
  numbro.registerLanguage(data)
})
numbro.setLanguage("de-DE")

export default {
  name: "input-view",
  keyboardEvent: null,
  data() {
    return {
      mchoice: null,
      guess: null,
      jeopardy: null,
      mchoiceValues: {
        0: "A",
        1: "B",
        2: "C",
        3: "D",
      },
    }
  },
  components: {},
  computed: {
    ...mapGetters([
      "getQuestionDetail",
      "getQuestionType",
      "getQuestionId",
      "getBuzzed",
      "getErrors",
      "getName",
      "getRevealed",
      "getAnswers",
      "getJokers",
    ]),
    getType() {
      return this.getQuestionType ?? null
    },
    hasConnection() {
      return this.$socket && this.$socket.connected
    },
    hasName() {
      return (
          this.getName !== "" &&
          this.getName !== undefined &&
          this.getName !== null
      )
    },
    hasBuzzes() {
      const playerJokers = this.getJokers.filter(
          (joker) => joker.c === this.$socket.client.id
      )
      const quJokers = this.getJokers.filter(
          (joker) => joker.q === this.getQuestionId
      )
      return (
          this.getAnswers.length &&
          playerJokers.length === 0 &&
          quJokers.length === 0
      )
      // && 1 === 2
    },
    formattedGuessInput() {
      if (!this.guess) {
        return ""
      }
      return numbro(this.guess).format({thousandSeparated: true})
    },
  },
  methods: {
    ...mapMutations(["setBuzzed"]),
    sendJoker() {
      if (this.hasConnection) {
        this.$socket.client.emit("joker", this.getQuestionId, null)
      }
    },
    buzz() {
      if (this.getBuzzed) {
        return
      }
      this.setBuzzed(true)
      if (this.hasConnection) {
        this.$socket.client.emit("answer", this.getQuestionId, null, {
          r: this.getRevealed,
        })
      }
    },
    answerMchoice(value) {
      if (this.hasConnection) {
        this.$socket.client.emit("answer", this.getQuestionId, null, {
          answer: value,
          r: this.getRevealed,
        })
      }
    },
    answerGuess() {
      if (this.hasConnection) {
        this.$socket.client.emit("answer", this.getQuestionId, null, {
          answer: this.guess,
          r: this.getRevealed,
        })
      }
    },
    answerJeopardy() {
      if (this.hasConnection) {
        this.$socket.client.emit("answer", this.getQuestionId, null, {
          answer: this.jeopardy,
          r: this.getRevealed,
        })
      }
    },
  },
  watch: {
    getQuestionId() {
      this.mchoice = null
      this.guess = null
      this.jeopardy = null
    },
    getType(nv, ov) {
      this.keyboardEvent = (e) => {
        if (
            e.keyCode === 32 &&
            this.hasName === true &&
            e.target == document.body
        ) {
          e.preventDefault()
          e.stopPropagation()
          this.buzz()
        }
      }
      if (nv === "buzz") {
        window.addEventListener("keydown", this.keyboardEvent)
      } else if (ov === "buzz") {
        window.removeEventListener("keydown", this.keyboardEvent)
      }
    },
  },
}
</script>