<template>
<v-dialog
    v-model="show"
    persistent
    width="300"
>
    <v-card
        color="primary"
        dark
    >
        <v-card-text class="pa-3">
            Please stand by ... connecting to server
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-5 mt-5"
            ></v-progress-linear>
        </v-card-text>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    name: 'please-wait',
    computed: {
        show() {
            return (this.$socket === null || (this.$socket && this.$socket.connected === false))
        }
    },
}
</script>